import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';

import { ConfigService } from 'app/services/config.service';
import { AvatarInteractionService } from 'app/services/avatar-interaction.service';
import { MicrophoneService } from 'app/services/microphone.service';
import { HttpService } from 'app/services/http.service';
import { MessagesService } from 'app/services/messages.service';
import { ComponentAbstract } from 'app/components/component.abstract';
import { MultimediaService } from 'app/services/multimedia.service';
import { LanguageService } from "app/services/language.service";
import { WebSocketMessage } from "app/models/webSocketMessage";
import { InteractionService } from "app/services/interaction.service";

@Component({
    selector: 'app-avatar-controls',
    templateUrl: './avatar-controls.component.html',
    styleUrl: './avatar-controls.component.scss'
})
export class AvatarControlsComponent extends ComponentAbstract implements OnInit, OnDestroy {
    @ViewChild('soundWaveRender') soundWaveRenderElement!: ElementRef;
    @ViewChild('audioPlayer') audioPlayerRef!: ElementRef<HTMLAudioElement>;
    @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;

    currentInputValue!: string;
    audioId!: string;
    currentAttachmentColor: string = 'rgba(85, 152, 254, 1)';
    currentMicrophoneColor: string = 'rgba(85, 152, 254, 1)';
    currentInputPlaceholder: string = 'Ask me a question...';

    soundWaveContainerWidth: number = 133;
    maximumSoundWaves: number = Math.floor((this.soundWaveContainerWidth - 20) / 4);
    minimalSpeechValue: number = 0.002
    maximumSpeechValue: number = 1.0
    minimalSoundWaveHeight: number = 10
    maximalSoundWaveHeight: number = 100
    soundWaveList: number[] = [];

    textDirection!: string;
    isVisible: boolean = false;
    isInteractionAllowed: boolean = false;
    inputTimeout: number | null = null;

    visibilitySubscription!: Subscription;
    closeMicrophoneSubscription!: Subscription
    waveAnimationRenderSubscription!: Subscription
    translateSubscription!: Subscription;
    interactionSubscription!: Subscription

    constructor(
        config: ConfigService,
        private visibility: AvatarInteractionService,
        private changeDetector: ChangeDetectorRef,
        protected microphone: MicrophoneService,
        protected renderer: Renderer2,
        protected multimedia: MultimediaService,
        protected http: HttpService,
        protected message: MessagesService,
        private language: LanguageService,
        private interaction: InteractionService
    ) {
        super(config);
    }

    ngOnInit() {
        this.visibilitySubscription = this
            .visibility
            .getVisibility('avatar-controls').subscribe(visible => {
                this.isVisible = visible;
            });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale);
            this.currentInputPlaceholder =  translate.typography.currentInputPlaceholder;
        });

        this.closeMicrophoneSubscription = this
            .microphone.closeMicrophoneEvent
            .subscribe(async audioFile => {
                await this.onMicrophoneClick();

                if (audioFile.size === 0) {
                    console.log('The audio message is empty');
                    return;
                }

                try {
                    const audio = await this.message.submitAudioData(audioFile);
                    // @ts-ignore
                    this.setAudioId(audio.body.id);
                    ["avatar-shortcuts", "avatar-drag-drop", "avatar-feedback"].map(key => this.visibility.hideComponent(key));
                    await this.message.createConversationRequest("transcription", this.getCurrentInputValue(), this.getAudioId());
                } catch (e: any) {
                }
            });

        this.waveAnimationRenderSubscription = this.microphone
            .waveAnimationRenderEvent.subscribe(probability => {
                this.soundWaveList.push(this.getSoundWaveHeight(probability.isSpeech));
                if (this.soundWaveList.length > this.maximumSoundWaves) this.soundWaveList.shift();

                this.renderSoundWaves(this.soundWaveRenderElement.nativeElement);
            });
    }

    // getters
    getAudioId (): string {
        return this.audioId;
    }

    getCurrentInputValue (): string {
        return this.currentInputValue
    }

    // setters
    setAudioId (id: string) {
        this.audioId = id
    }

   // events
    onShowShortcuts() {
        this.config.getConversationTabStatus()
            ? this.visibility.toggleComponent("avatar-shortcuts")
            : this.visibility.showComponent("avatar-shortcuts")
    }

    onOpenAttachments() {
        if (window.innerWidth < 768) {
            this.fileInputRef.nativeElement.click();
        }

        this.config.getConversationTabStatus()
            ? this.visibility.toggleComponent("avatar-drag-drop")
            : this.visibility.showComponent("avatar-drag-drop")
    }

    onChange(event: any) {
        const file: File = event.target.files[0];
        if (file) {
            this.multimedia.setFileValue(file);
        }
    }

    async onMicrophoneClick() {
        if (!this.microphone.getMicrophoneStatus() ) {
            this.currentMicrophoneColor = "red";
            await this.microphone.startMicrophone();
        } else {
            ["avatar-shortcuts", "avatar-drag-drop", "avatar-feedback"].map(key => this.visibility.hideComponent(key));

            this.soundWaveList = [];
            this.clearSoundWave(this.soundWaveRenderElement.nativeElement);

            this.microphone.stopMicrophoneAccess();
            this.currentMicrophoneColor = "rgba(85, 152, 254, 1)";
        }

        this.changeDetector.detectChanges()
    }

    onInput() {
    }

    async onSubmitMessage() {
        if (this.getCurrentInputValue() && this.getCurrentInputValue().trim() !== ''){
            ["avatar-shortcuts", "avatar-drag-drop", "avatar-feedback"].map(key => this.visibility.hideComponent(key));
            await this.message.createConversationRequest("text", this.getCurrentInputValue());
            this.currentInputValue = "";
        }
    }

    renderSoundWaves(htmlElement: Element): void {
        const newWave = (size: number) => {
            let wave = this.renderer.createElement('div');
            this.renderer.addClass(wave, 'bar');
            this.renderer.setStyle(wave, 'height', `${size}%`);
            this.renderer.appendChild(htmlElement, wave);
        }

        this.clearSoundWave(htmlElement);
        this.soundWaveList.forEach(wave => newWave(wave));
    }

    clearSoundWave(htmlElement: Element) {
        Array.from(htmlElement.childNodes).forEach(c => c.remove());
    }

    // helpers
    getSoundWaveHeight(decimal: number) {
        const normalizedValue = (decimal - this.minimalSpeechValue) / (this.maximumSpeechValue - this.minimalSpeechValue);
        return normalizedValue * (this.maximalSoundWaveHeight - this.minimalSoundWaveHeight) + this.minimalSoundWaveHeight;
    }

    ngOnDestroy() {
        this.visibilitySubscription.unsubscribe();
        this.interactionSubscription.unsubscribe();
        this.closeMicrophoneSubscription.unsubscribe()
        this.waveAnimationRenderSubscription.unsubscribe();
    }
}
